<template>
    <div>
        <div class="row data-form">
            <div class="col-12">
                <div class="mb-4">
                    <h6 class="text-uppercase mb-0">{{$t('placement_exam_part_classrooms_update_info')}}</h6>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="campus" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('campus')">
                                    <campus-selectbox
                                        :validateError="errors[0]"
                                        v-model="defineForm.campusId">
                                    </campus-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="building" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('building')">
                                    <building-selectbox
                                        :campus_id="defineForm.campusId"
                                        :validateError="errors[0]"
                                        v-model="defineForm.buildingId">
                                    </building-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="classroom" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('classroom')">
                                    <classroom-selectbox
                                        :building_id="defineForm.buildingId"
                                        :validateError="errors[0]"
                                        v-model="defineForm.classroom">
                                    </classroom-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                            <ValidationProvider name="nationalityOption" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('nationality_option')">
                                    <b-form-select
                                        v-model="defineForm.nationalityOption"
                                        :options="nationOptions"></b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                            <ValidationProvider name="rank" rules="required|numeric" v-slot="{valid, errors}">
                                <b-form-group :label="$t('rank')">
                                    <b-form-input
                                        type="number"
                                        v-model="defineForm.rank"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                            <ValidationProvider name="capacity" rules="required|numeric" v-slot="{valid, errors}">
                                <b-form-group :label="$t('capacity')">
                                    <b-form-input
                                        type="number"
                                        v-model="defineForm.capacity"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                            <ValidationProvider name="numberOfObservers" rules="required|numeric" v-slot="{valid, errors}">
                                <b-form-group :label="$t('number_of_observers')">
                                    <b-form-input
                                        type="number"
                                        v-model="defineForm.numberOfObservers"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>


                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox";
import PlacementExamPartClassroomsService from "@/services/PlacementExamPartClassroomsService";
import ClassroomService from "@/services/ClassroomService";
import BuildingService from "@/services/BuildingService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CampusSelectbox,
        BuildingSelectbox,
        ClassroomSelectbox

    },
    props: {
        id: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            defineForm: {
                campusId: null,
                buildingId: null,
                classroom: null,
                nationalityOption: null,
                rank: null,
                capacity: null,
                placementExamPartId: null,
                numberOfObservers: null
            },

            ca:null,
            bu:null,
            cl:null,

            nationOptions: []
        }
    },
    metaInfo() {
        return {
            title: this.$t("placement_exam_part_classrooms_title")
        }
    },
    methods: {
        getFormData() {
            PlacementExamPartClassroomsService.show(this.id).then(response => {
                let data = response.data.data
                this.cl = data.classroom_id
                this.defineForm.nationalityOption = data.nationality_option
                this.defineForm.rank = data.rank
                this.defineForm.capacity = data.capacity
                this.defineForm.placementExamPartId = data.placement_exam_part_id
                this.defineForm.numberOfObservers = data.number_of_observers

                ClassroomService.show(this.cl).then(response => {
                    this.bu = response.data.data.building_id
                    BuildingService.show(response.data.data.building_id).then(response => {
                        this.ca = response.data.data.campus_id
                    })
                })

                setTimeout(()=>{
                    this.defineForm.campusId = this.ca
                    this.defineForm.buildingId = this.bu
                    this.defineForm.classroom = this.cl
                }, 1000)
            })
        },

        async sendForm() {
            if(this.checkPermission('placementexampartclassroom_update')) {
                const isValid = await this.$refs.updateForm.validate();
                if (isValid) {
                    let formData = {
                        placement_exam_part_id: this.defineForm.placementExamPartId,
                        classroom_id: this.defineForm.classroom,
                        nationality_option: this.defineForm.nationalityOption,
                        rank: this.defineForm.rank,
                        capacity: this.defineForm.capacity,
                        number_of_observers: this.defineForm.numberOfObservers
                    }

                    PlacementExamPartClassroomsService.update(this.id, formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('hide')
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
                            if (e.data.message) {
                                this.$refs.updateForm.errors.campusId.push(this.$t('api.' + e.data.message));
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        }
                        else {
                            if (e.data.errors.classroom_id) {
                                this.$refs.updateForm.errors.classroom.push(e.data.errors.classroom_id[0]);
                            }
                            if (e.data.errors.nationality_option) {
                                this.$refs.updateForm.errors.nationalityOption.push(e.data.errors.nationality_option[0]);
                            }
                            if (e.data.errors.rank) {
                                this.$refs.updateForm.errors.rank.push(e.data.errors.rank[0]);
                            }
                            if (e.data.errors.capacity) {
                                this.$refs.updateForm.errors.capacity.push(e.data.errors.capacity[0]);
                            }
                            if (e.data.errors.number_of_observers) {
                                this.$refs.updateForm.errors.numberOfObservers.push(e.data.errors.number_of_observers[0]);
                            }
                        }
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },

        setNationOptions(){
            this.nationOptions = [
                {value: null, text: this.$t('select') },
                {value: "TR", text: "TR" },
                {value: "EN", text: "EN" },
                {value: "ALL", text: "ALL" }
            ]
        }
    },

    created() {
        this.setNationOptions()
    },

    watch: {
        id: {
            handler: function (val) {
                this.getFormData(val)
            }
        }
    }
};
</script>
