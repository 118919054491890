<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('placement_exam_part_classrooms_title')"
	                    :isColumns="true"
	                    :isNewButton="checkPermission('placementexampartclassroom_store')"
	                    :isRankButton="checkPermission('placementexampartclassroom_update')"
	                    @new-button-click="$refs.defineModal.$refs.commonModal.show()"
	                    @filter-div-status="datatable.filterStatus=$event"
	                    @rank-button-click="openRankingModal">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'">
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('placement_exam_part_classrooms_title')"
		                      :isColumns="true"
		                      :isNewButton="checkPermission('placementexampartclassroom_store')"
		                      :isRankButton="checkPermission('placementexampartclassroom_update')"
		                      @new-button-click="$refs.defineModal.$refs.commonModal.show()"
		                      @filter-div-status="datatable.filterStatus=$event"
		                      @rank-button-click="openRankingModal">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'">
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="number" size="sm" v-model="datatable.queryParams.filter.id"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal size="xl" ref="rankingModal">
                <template v-slot:CommonModalTitle>
                    <div>{{$t('placement_exam_part_classrooms_ranking')}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ranking :placement_exam_part_id="$route.params.id" :classes="datatable.rows" @hide="closeRankingModal"></ranking>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="xl" ref="defineModal">
                <template v-slot:CommonModalTitle>
                    <div>{{$t('placement_exam_part_classrooms_define')}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <define-form :placement_exam_part_id="$route.params.id" @hide="closeDefineModal"></define-form>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="xl" ref="editModal">
                <template v-slot:CommonModalTitle>
                    <div>{{$t('placement_exam_part_classrooms_update')}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <update-form :id="selectedId" @hide="closeEditModal"></update-form>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import Datatable from "@/components/datatable/Datatable";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import qs from "qs";
import CommonModal from "@/components/elements/CommonModal";
import UpdateForm from "./updateForm";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import PrepClassesService from "@/services/PrepClassesService";
import DefineForm from "./defineForm";
import PlacementExamPartClassroomsService from "@/services/PlacementExamPartClassroomsService";
import Ranking from "./Ranking";

export default {
    components: {
        AppLayout,
        Header,
	    HeaderMobile,

        Datatable,
        DatatableFilter,
        CommonModal,
        UpdateForm,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        DefineForm,
        Ranking
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons:[
                            {
                                text: this.$t('edit'),
                                class: 'ri-pencil-fill',
                                permission: "placementexampartclassroom_update",
                                callback:(row)=>{
                                    this.selectedId = row.id
                                    this.$refs.editModal.$refs.commonModal.show()
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line',
                                permission: "placementexampartclassroom_delete",
                                callback:(row)=>{
                                    this.deleteItem(row)
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        hidden: false,
                    },
                    {
                        label: this.$t('classroom_name'),
                        field: 'classroom_name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('nationality_option'),
                        field: 'nationality_option',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('rank'),
                        field: 'rank',
                        hidden: false,
                        sortable: true
                    },
                    {
                        label: this.$t('capacity'),
                        field: 'capacity',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('number_of_observers'),
                        field: 'number_of_observers',
                        hidden: false,
                        sortable: false
                    }

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'rank',
                    page: 1,
                    limit: 20
                }
            },
            selectedId: null
        }
    },
    metaInfo() {
        return {
            title: this.$t("placement_exam_part_classrooms_title")
        }
    },
    methods: {
        filter() {
            this.getRows();
        },

        filterClear() {
            this.filterSet()
            this.getRows();
        },

        filterSet(){
            this.datatable.queryParams.filter = {
                placement_exam_part_id: this.$route.params.id
            };
        },

        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },

        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },

        changeColumn(key){
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
        },

        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return PlacementExamPartClassroomsService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                }).finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        deleteItem(row){
            if(this.checkPermission('placementexampartclassroom_delete')){
                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('placement_exam_part_classroom_delete_confirm_text'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {
                    if(response.isConfirmed == true) {
                        PlacementExamPartClassroomsService.remove(row.id).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.getRows()
                        }).catch(e => {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        })
                    }
                })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },

        closeEditModal(){
            this.$refs.editModal.$refs.commonModal.hide()
            this.getRows()
        },

        closeDefineModal(){
            this.$refs.defineModal.$refs.commonModal.hide()
            this.getRows()
        },

        openRankingModal(){
            this.$refs.rankingModal.$refs.commonModal.show()
            this.getRows()
        },

        closeRankingModal(){
            this.$refs.rankingModal.$refs.commonModal.hide()
            this.getRows()
        }
    },

    created() {
        this.filterSet()
    },

    watch: {}
};
</script>
